const WNTextAreaInput = (props: any) => {
  // props: WNInputProps
  const { id, label, placeholder, value, optionalClass, onBlur, onChange } =
    props;

  return (
    <div className="text-area-input-wrapper">
      <label htmlFor={label} className="sup-label">
        {label}
      </label>
      <textarea
        id={id}
        value={value}
        placeholder={placeholder}
        aria-label={label}
        className={'wilderNet-text-area-input ' + optionalClass}
        onBlur={onBlur}
        onChange={onChange}
      />
    </div>
  );
};

export default WNTextAreaInput;
