import {
  ActionReducerMapBuilder,
  PayloadAction,
  createSlice,
} from '@reduxjs/toolkit';

import { getBlogList, getBlogShuffledList } from '../actions/blogListActions';

const initialState = {
  blogList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  blogShuffledList: [],
  isBlogListLoading: false,
  isBlogShuffledListLoading: false,
  blogListErrorMessage: '',
  blogShuffledListErrorMessage: '',
} as BlogListStateType;

// * create slice state
const blogListSlice = createSlice({
  name: 'blogListStateSlice',
  initialState,
  reducers: {
    resetBlogListState: () => initialState,
  },
  extraReducers: builder => {
    blogListsCases(builder);
    blogShuffledListsCases(builder);
  },
});

const blogListsCases = (
  builder: ActionReducerMapBuilder<BlogListStateType>
) => {
  builder.addCase(getBlogList.pending, (state: BlogListStateType) => {
    state.isBlogListLoading = true;
  });

  builder.addCase(
    getBlogList.fulfilled,
    (state: BlogListStateType, action: PayloadAction<BlogListSuccessType>) => {
      state.isBlogListLoading = false;
      state.blogList = action.payload;
    }
  );

  builder.addCase(
    getBlogList.rejected,
    (state: BlogListStateType, action: any) => {
      state.isBlogListLoading = false;
      state.blogListErrorMessage = action.payload?.detail;
    }
  );

  return builder;
};

const blogShuffledListsCases = (builder: ActionReducerMapBuilder<any>) => {
  builder.addCase(getBlogShuffledList.pending, (state: any) => {
    state.isBlogShuffledListLoading = true;
  });

  builder.addCase(
    getBlogShuffledList.fulfilled,
    (state: BlogListStateType, action: PayloadAction<any>) => {
      state.isBlogShuffledListLoading = false;
      state.blogShuffledList = action.payload;
    }
  );

  builder.addCase(
    getBlogShuffledList.rejected,
    (state: BlogListStateType, action: any) => {
      state.isBlogShuffledListLoading = false;
      state.blogShuffledListErrorMessage = action.payload?.detail;
    }
  );

  return builder;
};

export const { resetBlogListState } = blogListSlice.actions;
export default blogListSlice.reducer;
