import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendPostRequest } from './requestUtil';

export const postContactUsMessage = createAsyncThunk(
  'post-contact-us-message',
  async (body: ContactUsRequestType, { getState }) => {
    const state = getState() as AppState;
    const token = state.user.authToken;
    return sendPostRequest('/api/contact-us/', body, token);
  }
);
