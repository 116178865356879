const SelectedTagsComponent = (props: SelectedInterestsPropsTypes) => {
  const { selectedTags, handleRemoveInterest, isPresentational } = props;
  const SelectedTagElements = selectedTags?.map((interest: any) => (
    <div
      key={interest.value}
      className="interest-card"
      onClick={() => handleRemoveInterest(interest.value)}
    >
      {interest.value}
      {!isPresentational && <div className="remove-interest-icon">x</div>}
    </div>
  ));

  return (
    <div className="selected-interests-wrapper">{SelectedTagElements}</div>
  );
};

export default SelectedTagsComponent;
