import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import SideDrawerButtonComponent from '../components/NavigationComponents/SideDrawerButtonComponent';
import { setIsSideDrawerOpen } from '../store/reducers/navigationReducer';
import Backdrop from './Backdrop';

const SideDrawer = (props: any) => {
  const { whichUserFlow } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isHomeSelected, setIsHomeSelected] = useState(false);
  // const [isNotificationsSelected, setIsNotificationsSelected] = useState(false);
  const [isUploadSelected, setIsUploadSelected] = useState(false);
  const [isDonateSelected, setIsDonateSelected] = useState(false);
  const [isContactUsSelected, setIsContactUsSelected] = useState(false);
  const [isProfileSelected, setIsProfileSelected] = useState(false);

  const location = useLocation();

  const isSideDrawerOpen = useSelector(
    (state: AppState) => state.nav.isSideDrawerOpen
  );

  useEffect(() => {
    handleNavigateChange(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setIsOpen(isSideDrawerOpen);
  }, [isSideDrawerOpen]);

  const handleNavigateChange = (currentUrl: string) => {
    deselectAllNavButtons();
    switch (currentUrl) {
      case '/upload-blog':
        setIsUploadSelected(true);
        break;
      case '/donate':
        setIsDonateSelected(true);
        break;
      case '/contact-us':
        setIsContactUsSelected(true);
        break;
      case '/profile':
        setIsProfileSelected(true);
        break;
      case '/home':
        setIsHomeSelected(true);
        break;
      default:
        deselectAllNavButtons();
        break;
    }
  };

  const handleHomeNavButtonClick = () => {
    navigate('/home');
    dispatch(setIsSideDrawerOpen(false));
  };

  // const handleNotificationsNavButtonClick = () => {
  //   deselectAllNavButtons();
  //   setIsNotificationsSelected(true);
  //   dispatch(setIsSideDrawerOpen(false));
  // };

  const handleUploadNavButtonClick = () => {
    navigate('/upload-blog');
    dispatch(setIsSideDrawerOpen(false));
  };
  const handleDonateNavButtonClick = () => {
    navigate('/donate');
    dispatch(setIsSideDrawerOpen(false));
  };

  const handleContactUsNavButtonClick = () => {
    navigate('/contact-us');
    dispatch(setIsSideDrawerOpen(false));
  };

  const handleProfileNavButtonClick = () => {
    navigate('/profile');
    dispatch(setIsSideDrawerOpen(false));
  };

  const deselectAllNavButtons = () => {
    setIsContactUsSelected(false);
    setIsDonateSelected(false);
    setIsHomeSelected(false);
    // setIsNotificationsSelected(false);
    setIsProfileSelected(false);
    setIsUploadSelected(false);
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    dispatch(setIsSideDrawerOpen(!isOpen));
  };

  return (
    <div>
      <div
        className={`side-drawer-${whichUserFlow} side-drawer ${
          isSideDrawerOpen ? 'open' : ''
        }`}
      >
        <div className="side-drawer-flex">
          <div>
            <img src="/w-logo.jpg" alt="W Logo" className="w-logo" />
            <button onClick={toggleDrawer} className="close-button" />
            <div className="navigation-section">
              <SideDrawerButtonComponent
                buttonText="Home"
                iconName="home"
                isSelected={isHomeSelected}
                handleSideDrawerButtonClick={handleHomeNavButtonClick}
              />
              {/* <SideDrawerButtonComponent
                buttonText="Notifications"
                iconName="notifications"
                isSelected={isNotificationsSelected}
                handleSideDrawerButtonClick={handleNotificationsNavButtonClick}
              /> */}
              <SideDrawerButtonComponent
                buttonText="Upload a Blog"
                iconName="upload"
                isSelected={isUploadSelected}
                handleSideDrawerButtonClick={handleUploadNavButtonClick}
              />
              <SideDrawerButtonComponent
                buttonText="Donate"
                iconName="donate"
                isSelected={isDonateSelected}
                handleSideDrawerButtonClick={handleDonateNavButtonClick}
              />
              <SideDrawerButtonComponent
                buttonText="Contact Us"
                iconName="contact-us-icon"
                isSelected={isContactUsSelected}
                handleSideDrawerButtonClick={handleContactUsNavButtonClick}
              />
              <SideDrawerButtonComponent
                buttonText="Profile"
                iconName="profile"
                isSelected={isProfileSelected}
                handleSideDrawerButtonClick={handleProfileNavButtonClick}
              />
            </div>
          </div>
          <div className="side-drawer-terms-link">
            <a href={'/assets/pdf/Terms.pdf'} target="_blank" rel="noreferrer">
              <SideDrawerButtonComponent buttonText="Terms and Agreement" />
            </a>
            <a
              href={'/assets/pdf/Community-Guidelines.pdf'}
              target="_blank"
              rel="noreferrer"
            >
              <SideDrawerButtonComponent buttonText="Community Guidelines" />
            </a>
          </div>
        </div>
      </div>
      <Backdrop show={isOpen} handleClick={toggleDrawer} />
    </div>
  );
};

export default SideDrawer;
