const WNInput = (props: WNInputProps) => {
  const { id, label, placeholder, value, optionalClass, onBlur, onChange } =
    props;

  const isPassword = () => {
    return label.toLocaleLowerCase().includes('password');
  };

  return (
    <div className="input-wrapper">
      <label htmlFor={label} className="input-label">
        {label}
      </label>
      <input
        id={id}
        data-testid={id}
        value={value}
        placeholder={placeholder}
        aria-label={label}
        className={'wilderNet-input ' + optionalClass}
        type={isPassword() ? 'password' : 'text'}
        autoComplete={label}
        // onKeyDown={handleKeyDown}
        onBlur={onBlur}
        onChange={onChange}
      />
    </div>
  );
};

export default WNInput;
