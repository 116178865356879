import { ChangeEvent, FormEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import WNButton from '../components/FormComponents/WNButton';
import WNInput from '../components/FormComponents/WNInput';
import WNInputErrorMessage from '../components/FormComponents/WNInputErrorMessage';
import WNTextAreaInput from '../components/FormComponents/WNTextAreaInput';
import Heading from '../hoc/Heading';
import SuccessModal from '../hoc/SuccessModal';
import { postContactUsMessage } from '../store/actions/feedbackActions';
import { store } from '../store/store';

const ContactForm = () => {
  // Store Data
  const isAuthenticated = useSelector(
    (state: AppState) => state.user.isLoggedIn
  );
  // Local State
  const [emailInput, setEmailInput] = useState('');
  const [subjectLineInput, setSubjectLineInput] = useState<string>('');
  const [messageInput, setMessageInput] = useState<string>('');
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  // Local State for Error Messages
  const [emailInputErrorMessage, setEmailInputErrorMessage] =
    useState<string>('');
  const [subjectLineInputErrorMessage, setSubjectLineInputErrorMessage] =
    useState<string>('');
  const [messageInputErrorMessage, setMessageInputErrorMessage] =
    useState<string>('');

  const checkIfEmailIsValid = (emailProp: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (emailProp && emailProp !== '' && emailProp.match(emailPattern)) {
      return true;
    } else {
      return false;
    }
  };

  // Event Handlers for inputs
  const handleEmailInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmailInput(event.target.value);
    setEmailInputErrorMessage('');
  };

  const handleSubjectLineInputOnChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setSubjectLineInput(event.target.value);
    setSubjectLineInputErrorMessage('');
  };

  const handleMessageInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessageInput(event.target.value);
    setMessageInputErrorMessage('');
  };

  // Form Validation
  const validateEmailInput = () => {
    if (emailInput === '') {
      setEmailInputErrorMessage('please enter an email');
      return false;
    } else if (!checkIfEmailIsValid(emailInput)) {
      setEmailInputErrorMessage('Please enter a valid email');
      return false;
    } else {
      setEmailInputErrorMessage('');
      return true;
    }
  };

  const validateSubjectLineInput = () => {
    if (subjectLineInput === '') {
      setSubjectLineInputErrorMessage('Please add a subject line');
      return false;
    } else {
      setSubjectLineInputErrorMessage('');
      return true;
    }
  };

  const validateMessageInput = () => {
    if (messageInput === '') {
      setMessageInputErrorMessage('Please add a message');
      return false;
    } else {
      setMessageInputErrorMessage('');
      return true;
    }
  };

  // form submission
  const handleSubmitContactForm = async (event: FormEvent) => {
    event.preventDefault();

    if (!isAuthenticated) {
      console.error('User is not authenticated');
      return;
    }

    const emailInputIsValid = validateEmailInput();
    const subjectLineInputIsValid = validateSubjectLineInput();
    const messageInputIsValid = validateMessageInput();

    const contactFormData: ContactUsRequestType = {
      email: emailInput,
      subject: subjectLineInput,
      message: messageInput,
    };

    if (emailInputIsValid && subjectLineInputIsValid && messageInputIsValid) {
      const submitContactFormAction = await store.dispatch(
        postContactUsMessage(contactFormData)
      );

      if (postContactUsMessage.fulfilled.match(submitContactFormAction)) {
        setShowSuccessModal(true);
        // clear contact us form
        setEmailInput('');
        setSubjectLineInput('');
        setMessageInput('');
      } else if (postContactUsMessage.rejected.match(submitContactFormAction)) {
        console.error('Error submitting contact form');
      }
    }
  };

  const handleSuccessModalContinue = () => {
    setShowSuccessModal(false);
  };

  const userNotAuthenticatedMessage = (
    <div className="contact-us-form">
      User must be authenticated to contact us
    </div>
  );

  const contactUsFormJSX = (
    <form
      onSubmit={handleSubmitContactForm}
      name="contact-us-form"
      className="contact-us-form"
    >
      <WNInput
        id={'contact-us-email'}
        label={'Email'}
        placeholder={'Your Email'}
        value={emailInput}
        onChange={handleEmailInputOnChange}
        optionalClass={emailInputErrorMessage && 'input-error'}
      />
      <WNInputErrorMessage errorMessage={emailInputErrorMessage} />
      <WNInput
        id={'contact-us-subject'}
        label={'Subject Line'}
        placeholder={
          'E.g. bug report, inappropriate content, or suggestion for the site'
        }
        value={subjectLineInput}
        optionalClass={subjectLineInputErrorMessage && 'input-error'}
        onChange={handleSubjectLineInputOnChange}
      />
      <WNInputErrorMessage errorMessage={subjectLineInputErrorMessage} />
      <WNTextAreaInput
        id={'contact-us-message'}
        className={'contact-us-message'}
        label={'Message'}
        placeholder={'Write your message here'}
        value={messageInput}
        optionalClass={messageInputErrorMessage && 'input-error'}
        onChange={handleMessageInputOnChange}
      />
      <WNInputErrorMessage errorMessage={messageInputErrorMessage} />
      <WNButton
        buttonText="Submit"
        onClick={handleSubmitContactForm}
        disabled={!isAuthenticated}
      />
    </form>
  );

  return (
    <div className="contact-us-page">
      <Heading headingText="Contact Us" />
      {isAuthenticated ? contactUsFormJSX : userNotAuthenticatedMessage}
      <SuccessModal
        show={showSuccessModal}
        successText="Thank you for feedback!"
        handleContinue={handleSuccessModalContinue}
      />
    </div>
  );
};

export default ContactForm;
