import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import WNButton from '../../components/FormComponents/WNButton';
import WNInput from '../../components/FormComponents/WNInput';
import WNInputErrorMessage from '../../components/FormComponents/WNInputErrorMessage';
import WNTextAreaInput from '../../components/FormComponents/WNTextAreaInput';
import AddTagsFormComponent from '../../components/Tag Components/AddTagsFormComponent';
import Heading from '../../hoc/Heading';
import SuccessModal from '../../hoc/SuccessModal';
import { addBlogReview } from '../../store/actions/blogReviewActions';
import { store } from '../../store/store';

const BlogReviewForm = () => {
  const navigate = useNavigate();

  // Store & Param Data
  const { blog_id } = useParams();
  const blogId = parseInt(blog_id as string);
  const isAuthenticated = useSelector(
    (state: AppState) => state.user.isLoggedIn
  );
  const blogTitle = useSelector(
    (state: AppState) => state.blogDetails.blogDetails.title
  );

  // Local State
  const [reviewTitleInput, setReviewTitleInput] = useState<string>('');
  const [reviewContentInput, setReviewContentInput] = useState<string>('');
  const [isBlogRecommendedInput, setIsBlogRecommendedInput] =
    useState<Boolean | null>(null);
  const [selectedTagOptions, setSelectedTagOptions] = useState<TagOptionType[]>(
    []
  );
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  // Local State for Error Messages
  const [reviewTitleInputErrorMessage, setReviewTitleInputErrorMessage] =
    useState<string>('');
  const [reviewContentInputErrorMessage, setReviewContentInputErrorMessage] =
    useState<string>('');
  const [isBlogRecommendedErrorMessage, setIsBlogRecommendedErrorMessage] =
    useState<string>('');

  // Event Handlers for inputs
  const handleReviewTitleInputOnChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setReviewTitleInput(event.target.value);
    setReviewTitleInputErrorMessage('');
  };

  const handleReviewContentInputOnChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setReviewContentInput(event.target.value);
    setReviewContentInputErrorMessage('');
  };

  const handleIsBlogRecommendedInputOnChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setIsBlogRecommendedInput(event.target.value === 'true');
    setIsBlogRecommendedErrorMessage('');
  };

  const handleUpdateTagSelection = (tagSelection: TagOptionType[]) => {
    setSelectedTagOptions(tagSelection);
  };
  // Form Validation
  const validateReviewTitleInput = () => {
    if (reviewTitleInput === '') {
      setReviewTitleInputErrorMessage('Please add a short review title');
      return false;
    } else {
      setReviewTitleInputErrorMessage('');
      return true;
    }
  };

  const validateReviewContentInput = () => {
    if (reviewContentInput === '') {
      setReviewContentInputErrorMessage('Please add a review');
      return false;
    } else {
      setReviewContentInputErrorMessage('');
      return true;
    }
  };

  const validateIsBlogRecommendedInput = () => {
    if (isBlogRecommendedInput == null) {
      setIsBlogRecommendedErrorMessage('Please add a recommendation');
      return false;
    } else {
      setIsBlogRecommendedErrorMessage('');
      return true;
    }
  };

  const handleSubmitBlogReviewForm = async (event: FormEvent) => {
    event.preventDefault();

    if (!isAuthenticated) {
      console.error('User is not authenticated');
      return;
    }

    const reviewTitleInputIsValid = validateReviewTitleInput();
    const reviewContentInputIsValid = validateReviewContentInput();
    const isBlogRecommendedIsValid = validateIsBlogRecommendedInput();

    const tagStringArray = selectedTagOptions.map(tag => tag.value);
    const blogReviewFormData: SubmitBlogReviewRequestType = {
      blog: blogId,
      title: reviewTitleInput,
      content: reviewContentInput,
      isRecommended: isBlogRecommendedInput,
      tags: tagStringArray || [],
    };

    if (
      reviewTitleInputIsValid &&
      reviewContentInputIsValid &&
      isBlogRecommendedIsValid
    ) {
      const uploadReviewAction = await store.dispatch(
        addBlogReview(blogReviewFormData)
      );

      if (addBlogReview.fulfilled.match(uploadReviewAction)) {
        setShowSuccessModal(true);
      } else if (addBlogReview.rejected.match(uploadReviewAction)) {
        console.error('Error submitting review');
      }
    }
  };

  const handleSuccessModalContinue = () => {
    setShowSuccessModal(false);
    navigate(-1);
  };

  // isAuthenticated
  const userNotAuthenticatedMessage = (
    <div className="blog-review-form">
      User must be authenticated to post a review
    </div>
  );

  const recommendationLabel = 'Do you recommend this blog?';

  const reviewFormJSX = (
    <form
      onSubmit={handleSubmitBlogReviewForm}
      name="blog-review-form"
      className="blog-review-form"
    >
      <h2 className="blog-review-heading">Leave a review for {blogTitle}</h2>
      <WNInput
        id={'blog-review-title'}
        label={'Review Title'}
        placeholder={'Enter a short review title'}
        value={reviewTitleInput}
        onChange={handleReviewTitleInputOnChange}
        optionalClass={reviewTitleInputErrorMessage && 'input-error'}
      />
      <WNInputErrorMessage errorMessage={reviewTitleInputErrorMessage} />
      <WNTextAreaInput
        id={'blog-review-content'}
        className={'blog-review-content'}
        label={'Review'}
        placeholder={'Write your review here'}
        value={reviewContentInput}
        optionalClass={reviewContentInputErrorMessage && 'input-error'}
        onChange={handleReviewContentInputOnChange}
      />
      <WNInputErrorMessage errorMessage={reviewContentInputErrorMessage} />
      <label htmlFor={recommendationLabel} className="input-label">
        {recommendationLabel}
      </label>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        row
        onChange={handleIsBlogRecommendedInputOnChange}
      >
        <FormControlLabel
          value={true}
          control={<Radio />}
          label="Recommended"
        />
        <FormControlLabel
          value={false}
          control={<Radio />}
          label="Not Recommended"
        />
      </RadioGroup>
      <WNInputErrorMessage errorMessage={isBlogRecommendedErrorMessage} />

      <AddTagsFormComponent
        label={'Select Review Tags'}
        placeholder={'Search for tags Related to your review'}
        selectedTags={selectedTagOptions}
        allowCustomTags={true}
        handleUpdateTagSelection={handleUpdateTagSelection}
      />
      <WNButton
        buttonText="Submit"
        onClick={handleSubmitBlogReviewForm}
        disabled={!isAuthenticated}
      />
    </form>
  );

  return (
    <div className="blog-review-page">
      <Heading headingText={''} />
      {isAuthenticated ? reviewFormJSX : userNotAuthenticatedMessage}
      <SuccessModal
        show={showSuccessModal}
        successText="Your review has been successfully added!"
        handleContinue={handleSuccessModalContinue}
      />
    </div>
  );
};

export default BlogReviewForm;
