import { useEffect, useState } from 'react';

const SuccessModal = (props: SuccessModalProps) => {
  const { show, successText, handleContinue } = props;
  const [showModelClass, setShowModalClass] = useState('hide-modal');

  useEffect(() => {
    if (show) {
      setShowModalClass('');
    } else {
      setShowModalClass('hide-modal');
    }
  }, [show]);

  return (
    <div
      className={`success-modal fade ${showModelClass}`}
      id="statusSuccessModal"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-sm"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body text-center p-lg-4">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 130.2 130.2"
            >
              <circle
                className="path circle"
                fill="none"
                stroke="#486332"
                strokeWidth="6"
                strokeMiterlimit="10"
                cx="65.1"
                cy="65.1"
                r="62.1"
              />
              <polyline
                className="path check"
                fill="none"
                stroke="#486332"
                strokeWidth="6"
                strokeLinecap="round"
                strokeMiterlimit="10"
                points="100.2,40.2 51.5,88.8 29.8,67.5 "
              />
            </svg>
            <h4 className="text-success mt-3">Success!</h4>
            <p className="mt-3">{successText}</p>
            <button onClick={handleContinue}>Continue</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
