import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select, { components, SingleValue } from 'react-select';
import { getAllSubjectTags } from '../../store/actions/profileActions';
import { store } from '../../store/store';
import SelectedTagsComponent from './SelectedTagsComponent';

const AddTagsFormComponent = (props: any) => {
  const {
    label,
    placeholder,
    selectedTags = [],
    allowCustomTags = false,
    handleUpdateTagSelection,
  } = props;

  const [selectedTagOptions, setSelectedTagOptions] =
    useState<TagOptionType[]>(selectedTags);
  const [availableTagOptions, setAvailableTagOptions] = useState<
    TagOptionType[]
  >([]);

  const initialTagOptions: TagOptionType[] = useSelector(
    (state: AppState) => state.tag.availableTags
  );

  const filterTagOptions = (
    mainTagOptions: TagOptionType[],
    targetTagOptions: TagOptionType[]
  ) => {
    return mainTagOptions.filter(
      tag =>
        !targetTagOptions.some(
          (selectedTag: TagOptionType) => tag.value === selectedTag.value
        )
    );
  };

  useEffect(() => {
    if (!initialTagOptions || initialTagOptions.length === 0) {
      store.dispatch(getAllSubjectTags());
    } else if (selectedTags) {
      setAvailableTagOptions(filterTagOptions(initialTagOptions, selectedTags));
    } else {
      setAvailableTagOptions(initialTagOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTagOptions]);

  const handleInputChange = (event: any) => {
    if (allowCustomTags && event.key === 'Enter') {
      handleAddInterest(event.target.value);
    }
  };

  const handleAddInterest = (
    tagOption: SingleValue<TagOptionType> | string
  ) => {
    if (null == tagOption || null == availableTagOptions) return;
    const newTag =
      typeof tagOption === 'string'
        ? { label: tagOption, value: tagOption }
        : tagOption;

    const updatedTagOptions = [...selectedTagOptions, newTag];
    const updatedAvaiableTagOptions = availableTagOptions.filter(
      availableTagOption => availableTagOption.label !== newTag.label
    );

    setSelectedTagOptions(updatedTagOptions);
    setAvailableTagOptions(updatedAvaiableTagOptions);
    handleUpdateTagSelection(updatedTagOptions);
  };

  const handleRemoveInterest = (tagValue: string) => {
    const updatedTagOptions = selectedTagOptions.filter(
      selectedTag => selectedTag.value !== tagValue
    );
    const updatedAvaiableTagOptions = [
      ...availableTagOptions,
      { label: tagValue, value: tagValue },
    ];
    setSelectedTagOptions(updatedTagOptions);
    setAvailableTagOptions(updatedAvaiableTagOptions);
    handleUpdateTagSelection(updatedTagOptions);
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: '#F1F2EB',
      outline: 'none',
      padding: '10px',
      paddingLeft: '1.5em',
      marginBottom: '0px',
      boxShadow: 'none',
      borderRadius: '10px',
      cursor: 'pointer',
      border: '1px solid black',
      '&:focus': {
        outline: 'none',
      },
      '&:hover': {
        borderColor: 'gray',
      },
    }),
    dropdownIndicator: () => ({
      display: 'none',
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: '#F1F2EB',
      overflowY: 'scroll',
      scrollbarWidth: 'none',
      border: '1px solid black',
      borderRadius: '10px',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#F1F2EB' : '#F1F2EB',
      color: state.isFocused ? 'black' : 'black',
      padding: '10px',
      '&:hover': {
        backgroundColor: '#F1F2EB',
      },
      borderBottom: !state.data.isLast && '1px solid #C8C8C8',
    }),
  };

  const CustomControl = (props: any) => (
    <components.Control {...props}>
      <SearchIcon />
      {props.children}
    </components.Control>
  );

  const styleLastOptionInDropDown = (options: any) => {
    if (!options || 0 === options.length) return options;
    const lastIndex = options.length - 1;
    return options.map((option: object, index: number) => ({
      ...option,
      isLast: index === lastIndex,
    }));
  };

  return (
    <div className="your-interests-section">
      <div className="sup-label">{label}</div>
      <Select
        placeholder={placeholder}
        styles={customStyles}
        options={styleLastOptionInDropDown(availableTagOptions)}
        value={null}
        menuPosition="fixed"
        onKeyDown={handleInputChange}
        onChange={handleAddInterest}
        components={{ Control: CustomControl }}
      />
      <SelectedTagsComponent
        selectedTags={selectedTagOptions}
        handleRemoveInterest={handleRemoveInterest}
      />
    </div>
  );
};

export default AddTagsFormComponent;
