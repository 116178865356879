import {
  ActionReducerMapBuilder,
  PayloadAction,
  createSlice,
} from '@reduxjs/toolkit';

import {
  createProfile,
  getAllUserInfoByEmail,
  getUserByUsername,
  loginUser,
  signUpUser,
  uploadProfilePicture,
} from '../actions/authActions';

const initialState = {
  // User information
  userId: 0,
  authToken: '',
  email: '',
  username: '',
  // Profile information
  profilePicture: '',
  bio: '',
  interests: [],
  // User status
  isUserFound: false,
  isLoggedIn: false,
  isUserLoading: false,
  loginErrorMessage: '',
} satisfies UserStateType as UserStateType;

const userSlice = createSlice({
  name: 'userStateSlice',
  initialState,
  reducers: {
    resetUserState: () => initialState,
    setUserEmail: (state: UserStateType, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
  },
  extraReducers: builder => {
    userLoginCases(builder);
    userSignUpCases(builder);
    getAllUserInfoByEmailCases(builder);
    getUserByUsernameCases(builder);
    uploadProfilePictureCases(builder);
    createProfileCases(builder);
  },
});

const userLoginCases = (builder: ActionReducerMapBuilder<UserStateType>) => {
  builder.addCase(loginUser.pending, (state: UserStateType) => {
    state.isUserLoading = true;
  });

  builder.addCase(
    loginUser.fulfilled,
    (state: UserStateType, action: PayloadAction<any>) => {
      state.isUserLoading = false;
      state.isLoggedIn = true;
      state.loginErrorMessage = '';
      state.authToken = action.payload?.auth_token;
    }
  );

  builder.addCase(loginUser.rejected, (state: UserStateType, action: any) => {
    state.isUserLoading = false;
    state.isLoggedIn = false;
  });

  return builder;
};

const userSignUpCases = (builder: ActionReducerMapBuilder<UserStateType>) => {
  builder.addCase(signUpUser.pending, (state: UserStateType) => {
    state.isUserLoading = true;
  });

  builder.addCase(
    signUpUser.fulfilled,
    (state: UserStateType, action: PayloadAction<SignupLoginSuccessType>) => {
      state.isUserLoading = false;
      state.isLoggedIn = true;
      state.userId = action.payload.id;
      state.username = action.payload.username;
      state.email = action.payload.email;
    }
  );
  builder.addCase(signUpUser.rejected, (state: UserStateType) => {
    state.isUserLoading = false;
  });
  return builder;
};

const getAllUserInfoByEmailCases = (
  builder: ActionReducerMapBuilder<UserStateType>
) => {
  builder.addCase(getAllUserInfoByEmail.pending, (state: UserStateType) => {
    state.isUserLoading = true;
  });

  builder.addCase(
    getAllUserInfoByEmail.fulfilled,
    (state: UserStateType, action: PayloadAction<any>) => {
      const userRes = action.payload.user;
      state.userId = userRes?.id ? userRes.id : state.userId;
      state.email = userRes?.email ? userRes.email : state.email;
      state.username = userRes?.username ? userRes.username : state.username;

      const profileRes = action.payload.profile;
      state.bio = profileRes?.about_me ? profileRes.about_me : state.bio;
      state.interests = profileRes?.tags ? profileRes.tags : state.interests;
      state.profilePicture = profileRes?.avatar
        ? profileRes.avatar
        : state.profilePicture;

      state.isUserLoading = false;
    }
  );

  builder.addCase(getAllUserInfoByEmail.rejected, (state: UserStateType) => {
    state.isUserLoading = false;
  });
  return builder;
};

const getUserByUsernameCases = (
  builder: ActionReducerMapBuilder<UserStateType>
) => {
  builder.addCase(getUserByUsername.pending, (state: UserStateType) => {
    state.isUserLoading = true;
  });

  builder.addCase(
    getUserByUsername.fulfilled,
    (state: UserStateType, action: PayloadAction<getUserSuccessType>) => {
      state.isUserLoading = false;
    }
  );

  builder.addCase(
    getUserByUsername.rejected,
    (state: UserStateType, action: any) => {
      state.isUserLoading = false;
    }
  );
  return builder;
};

const uploadProfilePictureCases = (
  builder: ActionReducerMapBuilder<UserStateType>
) => {
  builder.addCase(uploadProfilePicture.pending, (state: UserStateType) => {});
  builder.addCase(
    uploadProfilePicture.fulfilled,
    (state: UserStateType, action: PayloadAction<any>) => {
      state.profilePicture = action.payload?.avatar;
    }
  );
  builder.addCase(
    uploadProfilePicture.rejected,
    (state: UserStateType, action: any) => {}
  );

  return builder;
};

const createProfileCases = (
  builder: ActionReducerMapBuilder<UserStateType>
) => {
  builder.addCase(createProfile.pending, (state: any) => {});
  builder.addCase(
    createProfile.fulfilled,
    (state: UserStateType, action: PayloadAction<any>) => {
      state.profilePicture = action.payload.avatar;
      state.bio = action.payload.about_me;
      state.interests = action.payload.tags;
    }
  );
  builder.addCase(
    createProfile.rejected,
    (state: UserStateType, action: any) => {}
  );
  return builder;
};

export const { resetUserState, setUserEmail } = userSlice.actions;
export default userSlice.reducer;
