import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendGetRequest, sendPostRequest } from './requestUtil';

// This returns a single blog by ID, with a list of discussions & reviews
export const getBlogById = createAsyncThunk(
  'get-blog-by-id',
  async (blogId: string | number | undefined): Promise<BlogPageSuccessType> => {
    // * currently using api data (below) for development

    // consolidated blogDetails
    const blogDetails: BlogCard = await sendGetRequest(`/api/blogs/${blogId}/`);

    // paginated blogReviewsList
    const blogReviewsList: BlogReviewsListSuccessType = await sendGetRequest(
      `/api/blogs/${blogId}/reviews/`
    );

    // paginated blogDiscussionsList
    const blogDiscussionsList: BlogDiscussionsListSuccessType =
      await sendGetRequest(`/api/blogs/${blogId}/discussions/`);

    let blogPageData: BlogPageSuccessType = {
      blogDetails,
      blogDiscussionsList,
      blogReviewsList,
    };

    return blogPageData;
  }
);

export const uploadBlogImage = createAsyncThunk(
  'upload-blog-image',
  async (body: FormData, { getState }) => {
    const state = getState() as AppState;
    const token = state.user.authToken;
    return sendPostRequest('/api/blogs/upload-image/', body, token);
  }
);

export const uploadBlog = createAsyncThunk(
  'upload-blog',
  async (uploadBlogData: UploadBlogRequestType, { getState }) => {
    const state = getState() as AppState;
    const token = state.user.authToken;
    return sendPostRequest('/api/upload-blog/', uploadBlogData, token);
  }
);
