import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendGetRequest } from './requestUtil';

// ! for now this is for testing getting a list of blogs
export const getBlogList = createAsyncThunk('get-blogs', async () => {
  const result: any = await sendGetRequest(`/api/blogs/`); //BlogListSuccessType
  return result;
});

export const getBlogShuffledList = createAsyncThunk(
  'get-shuffled-blogs',
  async () => {
    const result: BlogCard[] = await sendGetRequest(
      `/api/blogs/shuffled/?count=12`
    );

    return result;
  }
);
