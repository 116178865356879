// reviews/create/

import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendPostRequest } from './requestUtil';

export const addBlogReview = createAsyncThunk(
  'add-blog-review',
  async (body: SubmitBlogReviewRequestType, { getState }) => {
    const state = getState() as AppState;
    const token = state.user.authToken;

    return sendPostRequest('/api/reviews/create/', body, token);
  }
);
